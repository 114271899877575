import React, {FC, useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getPrinters,
} from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {
  getGT3PrintersSelector, getGTX600PrintersSelector, getGTX600sbPrintersSelector,
  getGTXPrintersSelector,
  getGTXProBulkPrintersSelector,
  getGTXProPrintersSelector,
  getPrintersSelector, getWF1L640PrintersSelector,
} from '../../selectors/printer';
import {PrinterList} from '../../components/Printer/PrinterList';
import {ROLE, RoleNameMap} from '../../components/Role/constants';
import {Filter, FilterGroup} from '../../components/Filter/FilterGroup';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_PRINTERS} from '../../store/printers/actionTypes';

/* eslint-disable no-unused-vars */
enum PRINTER_FILTER {
  ALL,
  GT3,
  GTX,
  GTXPRO,
  GTXPRO_BULK,
  GTX600,
  GTX600SB = 6,
  GTXPROR2R = 'GTXPROR2R',
  GTXPRO_BULKR2R = 'GTXPRO_BULKR2R',
  WF1_L640 = 'WF1_L640',
}

/* eslint-enable no-unused-vars */

const PrinterFilterLabelMap = new Map<PRINTER_FILTER, string>([
  [PRINTER_FILTER.ALL, 'ALL'],
  [PRINTER_FILTER.GT3, 'GT-3'],
  [PRINTER_FILTER.GTX, 'GTX'],
  [PRINTER_FILTER.GTXPRO, 'GTXpro'],
  [PRINTER_FILTER.GTXPRO_BULK, 'GTXpro Bulk'],
  [PRINTER_FILTER.GTX600, 'GTX600'],
  [PRINTER_FILTER.GTX600SB, 'GTX600SB'],
  [PRINTER_FILTER.GTXPROR2R, 'GTXpro R2R'],
  [PRINTER_FILTER.GTXPRO_BULKR2R, 'GTXpro Bulk R2R'],
  [PRINTER_FILTER.WF1_L640, 'WF1-L640'],
]);

const PrintersOverview: FC = () => {
  const allPrinters = useSelector(getPrintersSelector);
  const gt3Printers = useSelector(getGT3PrintersSelector);
  const gtxPrinters = useSelector(getGTXPrintersSelector);
  const gtxProPrinters = useSelector(getGTXProPrintersSelector);
  const gtxProBulkPrinters = useSelector(getGTXProBulkPrintersSelector);
  const gtx600Printers = useSelector(getGTX600PrintersSelector);
  const gtx600sbPrinters = useSelector(getGTX600sbPrintersSelector);
  const wf1l640Printers = useSelector(getWF1L640PrintersSelector);
  const dispatch = useDispatch();
  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  const [printers, setPrinters] = useState<Printer[]>(allPrinters);
  const [activeFilter, setActiveFilter] = useState(PRINTER_FILTER.ALL);

  const printersLoadingSelector = useSelector(
      createLoadingSelector([GET_PRINTERS]),
  );
  const [printersLoading, setPrintersLoading] = useState(true);

  const filters: Filter[] = [
    {
      active: activeFilter === PRINTER_FILTER.ALL,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.ALL) || '',
      method: () => setActiveFilter(PRINTER_FILTER.ALL),
      itemCount: allPrinters.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GT3,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GT3) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GT3),
      itemCount: gt3Printers.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTX,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTX) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GTX),
      itemCount: gtxPrinters.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTXPRO,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTXPRO) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GTXPRO),
      itemCount: gtxProPrinters.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTXPRO_BULK,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTXPRO_BULK) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GTXPRO_BULK),
      itemCount: gtxProBulkPrinters.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTX600,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTX600) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GTX600),
      itemCount: gtx600Printers.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTX600SB,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTX600SB) || '',
      method: () => setActiveFilter(PRINTER_FILTER.GTX600SB),
      itemCount: gtx600sbPrinters.length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTXPROR2R,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTXPROR2R) || '',
      method: () => {
        setActiveFilter(PRINTER_FILTER.GTXPROR2R);
      },
      itemCount: gtxProPrinters.filter((printer) => printer.machine).length,
    }, {
      active: activeFilter === PRINTER_FILTER.GTXPRO_BULKR2R,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.GTXPRO_BULKR2R) || '',
      method: () => {
        setActiveFilter(PRINTER_FILTER.GTXPRO_BULKR2R);
      },
      itemCount: gtxProBulkPrinters.filter((printer) => printer.machine).length,
    }, {
      active: activeFilter === PRINTER_FILTER.WF1_L640,
      label: PrinterFilterLabelMap.get(PRINTER_FILTER.WF1_L640) || '',
      method: () => setActiveFilter(PRINTER_FILTER.WF1_L640),
      itemCount: gtx600Printers.length,
    },
  ];

  useEffect(() => {
    dispatch(getPrinters());
  }, [dispatch]);

  useEffect(() => {
    switch (activeFilter) {
      case PRINTER_FILTER.ALL:
        setPrinters(allPrinters);
        break;
      case PRINTER_FILTER.GT3:
        setPrinters(gt3Printers);
        break;
      case PRINTER_FILTER.GTX:
        setPrinters(gtxPrinters);
        break;
      case PRINTER_FILTER.GTXPRO:
        setPrinters(gtxProPrinters);
        break;
      case PRINTER_FILTER.GTXPRO_BULK:
        setPrinters(gtxProBulkPrinters);
        break;
      case PRINTER_FILTER.GTX600:
        setPrinters(gtx600Printers);
        break;
      case PRINTER_FILTER.GTX600SB:
        setPrinters(gtx600sbPrinters);
        break;
      case PRINTER_FILTER.GTXPROR2R:
        setPrinters(gtxProPrinters.filter((printer) => printer.machine));
        break;
      case PRINTER_FILTER.GTXPRO_BULKR2R:
        setPrinters(gtxProBulkPrinters.filter((printer) => printer.machine));
        break;
      case PRINTER_FILTER.WF1_L640:
        setPrinters(wf1l640Printers);
        break;
    }
  }, [
    activeFilter,
    allPrinters,
    gt3Printers,
    gtxPrinters,
    gtxProPrinters,
    gtxProBulkPrinters,
    gtx600Printers,
    gtx600sbPrinters,
    wf1l640Printers,
  ],
  );

  useEffect(() => {
    setPrintersLoading(printersLoadingSelector);
  }, [printersLoadingSelector]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Printer" breadcrumbItem="Overview"/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {[
                    ROLE.BROTHER_MANAGER,
                    ROLE.BROTHER_STAFF,
                    ROLE.DEALER_ENGINEER,
                  ].includes(userRole) &&
                    <>
                      <div className="text-sm-right">
                        <Link to={{pathname: '/add-printer'}}>
                          <Button
                            type="button"
                            color="yellow"
                            className="btn-rounded waves-effect waves-light
                            mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"/>
                            Add New Printer
                          </Button>
                        </Link>
                      </div>
                      <div className="d-flex">
                        <FilterGroup
                          filters={filters}
                          isLoading={printersLoading}
                        />
                      </div>
                    </>
                  }
                  <PrinterList
                    printers={printers}
                    isLoading={printersLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrintersOverview;

